import React, { createContext, useState, useContext } from 'react'
import loadable from '@loadable/component'
const SuccessModal = loadable(() => import('../component/Modals/SuccessModal'))
const ConfirmModal = loadable(() => import('../component/Modals/ConfirmModal'))

const GeneralModalContext = createContext()

export const useGeneralModal = () => useContext(GeneralModalContext)

const GeneralModalProvider = ({ children }) => {
  const [successModal, setSuccessModal] = useState(null)
  const [confirmModal, setConfirmModal] = useState(null)

  return (
    <GeneralModalContext.Provider value={{ setSuccessModal, setConfirmModal }}>
      {children}
      {successModal && <SuccessModal {...successModal} />}
      {confirmModal && <ConfirmModal {...confirmModal} />}
    </GeneralModalContext.Provider>
  )
}

export default GeneralModalProvider
