import { isImageData } from './imageUtil'

export const isBoolean = val => 'boolean' === typeof val
export const isString = val => 'string' === typeof val

export const refactorSoundSettings = music => {
  const initMusic = {
    volume: 0.5,
    soundOn: true,
    starting: 'button-sound',
    spinning: 'tick-sound',
    celebrating: 'celebration-sound',
    customOn: false,
  }
  const {
    // volume,
    starting: startingG,
    spinning: spinningG,
    celebrating: celebratingG,
    ...rest
    // soundOn,
    // customOn,
  } = music
  const starting = !isBoolean(startingG)
    ? startingG
    : startingG
    ? 'button-sound'
    : 'no-sound'
  const spinning = !isBoolean(spinningG)
    ? spinningG
    : spinningG
    ? 'tick-sound'
    : 'no-sound'
  const celebrating = !isBoolean(celebratingG)
    ? celebratingG
    : celebratingG
    ? 'celebration-sound'
    : 'no-sound'

  return {
    // volume: volume !== undefined ? volume : 0.5, //refactor sound volume 1st june 2022
    // soundOn,
    ...initMusic,
    starting,
    spinning,
    celebrating,
    ...rest,
    // customOn: customOn !== undefined ? customOn : false, //refactor sound customOn 2nd sep 2023
  }
}

export const refactorSpinBehavior = behavior => {
  const initSpinBehavior = {
    speed: 5,
    duration: 10,
    manualStop: false,
    randomStart: true,
    mysterySpin: false,
    spinQuantity: false,
    initialSpinning: true,
  }
  return {
    ...initSpinBehavior,
    ...behavior,
    // randomStart:
    //   behavior.randomStart !== undefined ? behavior.randomStart : true,
  }
}

export const refactorBgColorSettings = bgColor => {
  if (!isString(bgColor)) return bgColor

  return {
    color: bgColor,
    theme: 'Default',
  }
}

export const refactorEvenSplitRTG = evenSplit => {
  if (isBoolean(evenSplit)) {
    return !evenSplit ? 'Default' : 'Gender'
  }
  return evenSplit
}

export const resetInputsImage = inputs => {
  //reset input.name image to empty array
  for (let input of inputs) {
    if (isImageData(input.name)) {
      return []
    }
  }
  return inputs
}

export const refactorTitle = title => {
  //reset input.name image to empty array
  return {
    ...title,
    description: title.description || '',
    popup: title.popup || '',
  }
}

export const daysBetween = (first, second) => {
  // Copy date parts of the timestamps, discarding the time parts.
  const one = new Date(first.getFullYear(), first.getMonth(), first.getDate())
  const two = new Date(
    second.getFullYear(),
    second.getMonth(),
    second.getDate(),
  )

  // Do the math.
  const millisecondsPerDay = 1000 * 60 * 60 * 24
  const millisBetween = two.getTime() - one.getTime()
  const days = millisBetween / millisecondsPerDay

  // Round down.
  return Math.floor(days)
}

export const withTimeout = (millis, promise) => {
  const timeout = new Promise((resolve, reject) =>
    setTimeout(() => reject({ code: 'timeout' }), millis),
  )
  return Promise.race([promise, timeout])
}

export const removeObjectField = (obj, field) => {
  // delete filter[selectName]; -> this mutates.
  const { [field]: remove, ...rest } = obj

  return rest
}

export const diffBy = (a, b, f) => a.filter(v => !b.some(u => f(v, u)))

export const intersectionBy = (a, b, f) => a.filter(v => b.some(u => f(v, u)))

export const replaceCDN = url => {
  //9Nov2023 disabled bunnycdn cache because average bandwidth use just 300mb, not worth to let users to wait the purge cache. if want to use cdn in future, please improve purgecdn time without async.
  return url
  // if (!url || !url.includes('https')) return url
  // const originUrl =
  //   'https://firebasestorage.googleapis.com/v0/b/' +
  //   process.env.GATSBY_FIREBASE_STORAGE_BUCKET +
  //   '/o'
  // return url.replace(originUrl, process.env.GATSBY_PW_CDN)
}

export const convertToDataUrl = blobOrFile => {
  const reader = new FileReader()
  reader.readAsDataURL(blobOrFile)
  return new Promise(
    resolve =>
      (reader.onload = function () {
        resolve(reader.result)
      }),
  )
}

export const urlToFile = async (url, fileName) => {
  const blob = await (await fetch(url)).blob()
  return new File([blob], fileName, { type: blob.type })
}

export const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
