import React from 'react'
import styled, { css } from 'styled-components'

const Button = styled.button`
  cursor: pointer;
  display: flex;
  background: none;
  border: none;
  color: ${props => props.theme.global.colors[props.color]};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  text-underline-offset: ${props => (props.underline ? '2px' : 'none')};
  font-weight: ${props => (props.weight ? props.weight : 400)};
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
    `}
  &:hover {
    text-decoration: underline;
  }
`

const TextButton = props => {
  return (
    <Button
      {...props}
      disabled={props.loading || props.disabled}
      underline={props.underline || false}
    >
      {props.label}
    </Button>
  )
}

export default TextButton
