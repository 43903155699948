import { createReducer } from '../reducerUtil'
import {
  DISPLAY_LIST_MODAL,
  DISPLAY_LOAD_SAVE_MODAL,
  // DISPLAY_SUCCESS_MODAL,
  DISPLAY_CUSTOM_TEAM_MODAL,
  DISPLAY_VIDEO_MODAL,
  DISPLAY_MODIFY_TITLE_MODAL,
  DISPLAY_NEW_WHEEL_DROP,
  DISPLAY_PRESET_GROUP_MODAL,
  SET_RESULT_MODAL_APPEAR,
} from './constants'

// successModal:{
//   type: 'SuccessWithAction',
//   title: 'Successful!',
//   message: 'Thank you for signing up with us',
//   action: () => {},
// }

const initialState = {
  listModalDisplay: '',
  loadSaveModalDisplay: null,
  // successModal: null,
  customTeamModal: false,
  videoModal: null,
  modifyTitleModal: false,
  newWheelDrop: false,
  presetGroupModal: false,
  resultModalAppear: false,
}

export const displayListModal = (state, payload) => {
  return {
    ...state,
    listModalDisplay: payload.display,
  }
}

export const displayLoadSaveModal = (state, payload) => {
  return {
    ...state,
    loadSaveModalDisplay: payload.display,
  }
}

// export const displaySuccessModal = (state, payload) => {
//   return {
//     ...state,
//     successModal: payload.display,
//   }
// }

export const displayCustomTeamModal = (state, payload) => {
  return {
    ...state,
    customTeamModal: payload.display,
  }
}

export const displayVideoModal = (state, payload) => {
  return {
    ...state,
    videoModal: payload.video,
  }
}

export const displayModifyTitleModal = (state, payload) => {
  return {
    ...state,
    modifyTitleModal: payload.display,
  }
}

export const displayNewWheelDrop = (state, payload) => {
  return {
    ...state,
    newWheelDrop: payload.display,
  }
}

export const displayPresetGroupModal = (state, payload) => {
  return {
    ...state,
    presetGroupModal: payload.display,
  }
}

export const setResultModalAppear = (state, payload) => {
  return {
    ...state,
    resultModalAppear: payload.appear,
  }
}

export default createReducer(initialState, {
  [DISPLAY_LIST_MODAL]: displayListModal,
  [DISPLAY_LOAD_SAVE_MODAL]: displayLoadSaveModal,
  // [DISPLAY_SUCCESS_MODAL]: displaySuccessModal,
  [DISPLAY_CUSTOM_TEAM_MODAL]: displayCustomTeamModal,
  [DISPLAY_VIDEO_MODAL]: displayVideoModal,
  [DISPLAY_MODIFY_TITLE_MODAL]: displayModifyTitleModal,
  [DISPLAY_NEW_WHEEL_DROP]: displayNewWheelDrop,
  [DISPLAY_PRESET_GROUP_MODAL]: displayPresetGroupModal,
  [SET_RESULT_MODAL_APPEAR]: setResultModalAppear,
})
