import { createReducer } from "../reducerUtil"
import { SET_IP_LOCATION } from "./constants"

const initialState = {
  IPLocation: {},
}

export const setIPLocation = (state, payload) => {
  return {
    ...state,
    IPLocation: payload.data,
  }
}

export default createReducer(initialState, {
  [SET_IP_LOCATION]: setIPLocation,
})
